import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ActionedProductsTable from './ActionedProdcutsTable';
import { useParams } from 'react-router-dom';
import Header from '../Header/Header';
import Loader from '../Loader/Loader';

const ActionedProducts = () => {
    const [product, setProduct] = useState(null);
    const { productID } = useParams();
    const hostname = process.env.REACT_APP_SERVER_HOSTNAME
    const token = localStorage.getItem('copyrightToken');
    console.log({ "Product": product })

    const fetchActionedProducts = async () => {
        try {
            const config = {
                headers: {
                    Authorization: token
                }
            }
            const response = await axios.get(`${hostname}/api/product/getAllActionedProducts/${productID}`, config);
            setProduct(response.data.actionedProducts);
        } catch (error) {
            console.error('Error fetching actioned products:', error);
        }
    };

    useEffect(() => {
        fetchActionedProducts();
    }, [productID]);

    return (
        <>
            <Header />
            <div className="container mx-auto py-8">
                <p className='text-5xl font-extrabold mt-3 text-primary m-5 text-center'>Actioned Products</p>
                {product && <ActionedProductsTable products={product} />}
                {!product && (
                    <div className='flex items-center justify-center h-full'>
                        <Loader />
                    </div>
                )}
            </div>
        </>
    );
};

export default ActionedProducts;
