import React, { useContext, useState } from 'react';
import Context from '../../Context/Context';
import axios from 'axios';
import Loader1 from '../Loader/Loader1';

const MainProduct = ({ productName, productImage }) => {
    const [reason, setReason] = useState('Other');
    const [loading, setLoading] = useState('');
    const contextData = useContext(Context);
    const { selectedProduct, productID, setSearchImageResults, searchImageResults, setMainImage, setSelectedProduct } = contextData;
    const hostname = process.env.REACT_APP_SERVER_HOSTNAME

    const handleAction = async (clickedAction) => {
        setLoading(true)
        try {
            const actionedProduct = { action: clickedAction, actionType: reason, ...selectedProduct };
            const token = localStorage.getItem('copyrightToken');
            const config = {
                headers: {
                    Authorization: token
                }
            };
            await axios.post(
                `https://copyrightapi.leetwolf.org/api/product/addActionedProduct/${productID}`, { product: actionedProduct }, config
            );
            handleRemoveItem();

        } catch (error) {
            console.error('Error:', error);
        }
        setLoading(false)
    };

    const handleRemoveItem = () => {
        let updateProducts = searchImageResults.filter(item => item != selectedProduct)
        setSearchImageResults(updateProducts)
        setMainImage(updateProducts[0].original)
        setSelectedProduct(updateProducts[0])
    }

    return (
        <>
            {loading && <Loader1 />}
            <div className="flex flex-col items-center w-3/4 h-full rounded-md">
                <h2 className=" w-full text-xl font-semibold mb-4 text-center h-10 bg-primary flex items-center justify-center text-white py-1 hover:underline "><a className='w-full' href={selectedProduct.source} target="_blank">{productName}</a></h2>
                <div className='flex w-full h-4/5 '>
                    <img src={productImage} alt={productName} className="h-full mb-4 w-3/4 object-contain border-b-2 border-primary pb-3" />
                    <div className="flex flex-col ml-8">
                        {/* <div className='rounded-full w-32 h-32 border border-primary overflow-hidden mb-2'>
                        <img className="w-full h-full" src="https://via.placeholder.com/50" alt="Small image 1" />
                    </div>
                    <div className='rounded-full w-32 h-32 border border-primary overflow-hidden mb-2'>
                        <img src="https://via.placeholder.com/50" alt="Small image 2" className="w-full h-full" />
                    </div>
                    <div className='rounded-full w-32 h-32 border border-primary overflow-hidden mb-2'>
                        <img src="https://via.placeholder.com/50" alt="Small image 3" className="w-full h-full" />
                    </div>
                    <div className='rounded-full w-32 h-32 border border-primary overflow-hidden mb-2'>
                        <img src="https://via.placeholder.com/50" alt="Small image 4" className="w-full h-full" />
                    </div> */}
                    </div>
                </div>

                <div className="flex items-center mb-4 space-x-4 gap-32 w-full flex-wrap mt-2">
                    <div>
                        <select
                            id="reason"
                            name="reason"
                            className="block w-52 py-1 px-3 border border-primary gray-300 bg-white shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                        >
                            <option value="Brand Abuse">Brand Abuse</option>
                            <option value="Counterfeit">Counterfeit</option>
                            <option value="Copyright">Copyright</option>
                            <option value="Design Patent">Design Patent</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div className='flex gap-5'>
                        <button className="bg-transparent  font-semibold  hover:bg-primary hover:text-white py-1 px-8 border rounded-full border-primary " onClick={handleRemoveItem}>
                            Ignore
                        </button>
                        <button className="bg-transparent font-semibold hover:bg-primary hover:text-white py-1 px-8 border rounded-full border-primary" onClick={() => handleAction('Hold')}>
                            On Hold
                        </button>
                        <button className="bg-primary text-white py-1 font-semibold px-8 border rounded-full " onClick={() => handleAction('Take Down')}>
                            Take Down
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainProduct;
