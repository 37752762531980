import React from 'react';

const ActionedProductsTable = ({ products }) => {
    return (
        <table className="m-auto w-11/12 border border-solid">
            <thead className='bg-primary text-white border'>
                <tr>
                    <th className="px-4 py-2 text-xs font-medium">IMAGE</th>
                    <th className="px-4 py-2 text-xs font-medium">PRODUCT NAME</th>
                    <th className="px-4 py-2 text-xs font-medium">ACTION</th>
                    <th className="px-4 py-2 text-xs font-medium">ACTION TYPE</th>
                    <th className="px-4 py-2 text-xs font-medium">LINK</th>
                </tr>
            </thead>
            <tbody className='text-center'>
                {products && products.length > 0 ? (
                    products.map((actionedProduct, index) => (
                        <tr key={index}>
                            <td className="border px-4 py-2"><img src={actionedProduct.original} alt="Product" className="h-14 w-14" /></td>
                            <td className="border px-4 py-2">{actionedProduct.title}</td>
                            <td className="border px-4 py-2">{actionedProduct.action}</td>
                            <td className="border px-4 py-2">{actionedProduct.actionType}</td>
                            <td className="border px-4 py-2">
                                <a href={actionedProduct.source} target="_blank" rel="noreferrer">
                                    <button className="text-green-500 py-2 px-4 rounded hover:text-primary">
                                        Click Here
                                    </button>
                                </a>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="5" className="text-center py-2 text-lg">No products available</td>
                    </tr>
                )}

            </tbody>
        </table>
    );
};

export default ActionedProductsTable;
