import React, { useContext, useEffect, useState } from 'react';
import Context from '../../Context/Context';
import Loader from '../Loader/Loader';

const ProductsList = () => {
    const contextData = useContext(Context);
    const { searchImageResults , setMainImage , setSelectedProduct } = contextData;
    const [activeProduct, setActiveProduct] = useState(null);

    const handleClick = (product) => {
        setActiveProduct(product.original);
        setMainImage(product.original);
        setSelectedProduct(product)
    };

    useEffect(()=>{
     setSelectedProduct(searchImageResults[0])
    },[])

    return (
        <div className="w-1/4 h-full overflow-y-auto rounded-md bg-gray-200" style={{ scrollbarWidth: '1px' }}> 
            <h2 className="text-xl font-semibold mb-4 text-center h-10 bg-primary flex items-center justify-center text-white">Similar Items</h2>
            <div className='p-4'>
                <div className="mb-4">
                    <label htmlFor="sortBy" className="block text-sm font-medium text-gray-700">
                        Sort By
                    </label>
                    <select
                        id="sortBy"
                        name="sortBy"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    >
                        <option value="relevant">Relevant</option>
                        <option value="newest">Newest</option>
                        <option value="priceLowToHigh">Price: Low to High</option>
                        <option value="priceHighToLow">Price: High to Low</option>
                    </select>
                </div>
                <ul>
                    {searchImageResults ? searchImageResults.map((product, index) => (
                        <li key={index} 
                            className={`flex items-center border border-gray-300 mb-4 bg-white p-2 cursor-pointer gap-3 text-sm overflow-hidden hover:border hover:border-gray-500 ${activeProduct === product.original ?  'border border-primary ' : ''}`}
                            onClick={() => handleClick(product)}
                        >
                            <div className="w-24 h-20 flex-shrink-0">
                                <img src={product.original} alt={product.original} className="w-full h-full" />
                            </div>
                            <span>{product.title}</span>
                        </li>
                    )) : <li> <Loader /> </li>}
                </ul>
            </div>
        </div>
    );
};

export default ProductsList;
