import React, { useState, useEffect, useContext } from 'react';
import Header from '../Header/Header';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import Loader1 from '../Loader/Loader1';
import axios from 'axios';
import Context from "../../Context/Context"

const MyProducts = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false)
    const [loading1, setLoading1] = useState(false)
    // console.log(products)
    const token = localStorage.getItem('copyrightToken');
    const hostname = process.env.REACT_APP_SERVER_HOSTNAME;
    const contextData = useContext(Context)
    const {setSearchImageResults , setProductID} = contextData
    const navigate = useNavigate()

    const fetchUserProducts = async () => {
        try {
            setLoading(true)
            const response = await fetch(`${hostname}/api/product/getProducts`, {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch user products');
            }

            const data = await response.json();
            setProducts(data);
            setLoading(false);

        } catch (error) {
            console.log('Error fetching user products:', error.message);

        }
    };

    useEffect(() => {
        if (token) {
            fetchUserProducts();
        }
    }, [token]);

    const handleNewSearch = async (productID, imageUrl) => {
        setLoading1(true)
        try {
            const response = await axios.post(`https://copyrightapi.leetwolf.org/api/product/searchImage?imageUrl=${imageUrl}&productID=${productID}`);
            setSearchImageResults(response.data.inlineImages)
            setProductID(response.data.productID)
            navigate("/searchedResult");
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setLoading1(false)
    }

    return (
        <>
        {loading1&& <Loader1/> }
            <Header />
            <p className='text-5xl font-extrabold mt-3 text-primary m-5 text-center'>My Products</p>
            <div className="overflow-x-auto h-4/5">
                <table className="m-auto table-auto w-11/12 divide-y border overflow-scroll divide-gray-200 text-center">
                    <thead className="bg-gray-50 sticky top-0">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-xs font-medium bg-primary text-white uppercase tracking-wider">
                                Product Image
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium bg-primary text-white uppercase tracking-wider">
                                Product Name
                            </th>
                            <th scope="col" className="px-6 py-3 text-xs font-medium bg-primary text-white uppercase tracking-wider">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    {!loading && <tbody className="bg-white divide-y divide-gray-200">
                        {products.map((product, index) => (
                            <tr key={index} className=''>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className='h-14 w-14'><img className=" m-auto w-full h-full " src={product.productImageUrl} alt="Product Image" /></div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <p>{product.productName}</p>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {/* <button className='bg-primary'>Actioned Products</button> */}
                                    <Link to={`/product/${product._id}/actionedProducts`}><button className='cursor-pointer bg-red-500 text-white border px-2 py-1 hover:bg-primary'>Actioned Products&rarr;</button></Link>
                                    <button className='cursor-pointer bg-green-600 text-white border  px-2 py-1 hover:bg-primary' onClick={() => handleNewSearch(product._id, product.productImageUrl)}>New Search&rarr;</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>}
                    {
                        loading && <tbody>
                            <tr>
                                <td colSpan="5" ><Loader /></td>
                            </tr>
                        </tbody>
                    }
                </table>
            </div>
        </>
    );
};

export default MyProducts;
