import React, { useState } from 'react'
import Icon from "../../asset/Icon/Illustrative"
import { useNavigate } from "react-router-dom"
import axios from 'axios'
import Loader1 from '../Loader/Loader1'

function Auth() {
  const navigate = useNavigate()
  const [loginInput, setLoginInput] = useState({})
  const hostname = process.env.REACT_APP_SERVER_HOSTNAME
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const onChange = (e) => {
    setLoginInput({ ...loginInput, [e.target.name]: e.target.value })
  }

  const handleLogin = async (e) => {
    setLoading(true)
    e.preventDefault();
    const url = `${hostname}/api/auth/login`
    const body = loginInput
    try {
      const { data } = await axios.post(url, body)
      localStorage.setItem("copyrightToken", data.token)
      navigate("/search")
    }
    catch (error) {
      console.log(error.message)
      setError(true);
    }
    setLoading(false)
  }


  return (
    <>
      {loading && <Loader1 />}

      <div className="grid grid-cols-7 h-screen">
        <div className=" grid bg-gray-100 col-span-3 items-center">
          <div className='p-12'>
            <Icon /></div></div>
        <div className="grid col-span-4 items-center px-10">
          <div>
            <p className='text-4xl font-semibold'>Welcome to</p>
            <p className='text-5xl font-extrabold mt-3 mb-5 text-primary'>Image Checker</p>
            <form className='mt-5'>
              {error?<p className=' w-3/4 px-4 pt-2  text-red-500 text-center font-bold' ><span className=''>Authentication failed : </span> Please type correct Email or Password</p>
              : <p className=' w-3/4 px-4 pt-2  text-red-500 text-center font-bold' >&nbsp; &nbsp; &nbsp; </p>}
              <div className={`bg-gray-200 px-4 mx-5 w-3/4 rounded-md`}>
                <div>
                  <div> <label className='text-xs px-3'>Email</label></div>
                  <div><input className='bg-transparent border-gray-100 px-3 py-1 w-full' type='email' onChange={onChange} name='username' placeholder='johndoe@example.com' /></div>
                </div>
              </div>
              <div className='bg-gray-200 px-4 py-2 m-5 w-3/4 rounded-md'>
                <div>
                  <div> <label className='text-xs px-3'>Password</label></div>
                  <div><input className='bg-transparent border-gray-100 px-3 py-1 w-full' name="password" onChange={onChange} type='password' placeholder='******************' /></div>
                </div>
              </div>

              <div className='bg-primary px-4 py-2 m-5 w-3/4 rounded-md mt-16 hover:bg-blue-400'>
                <button type='submit' onClick={handleLogin} className='text-center p-3 w-full text-white font-semibold border-none'>Login</button>
              </div>

            </form>
          </div>
        </div>
      </div>

    </>
  )
}

export default Auth
