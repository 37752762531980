import React, { useState } from 'react'
import Context from './Context'

function State(props) {
    const [searchImageResults, setSearchImageResults] = useState([])
    const [mainImage, setMainImage] = useState("")
    const [selectedProduct, setSelectedProduct] = useState({})
    const [productID, setProductID] = useState("")
    return (
        <Context.Provider value={{
            searchImageResults, setSearchImageResults, mainImage, setMainImage,
            selectedProduct, setSelectedProduct , productID ,setProductID
        }} >
            {props.children}
        </Context.Provider>
    )
}

export default State
