import React, { useContext } from 'react'
import ProductsList from './ProductsList'
import MainProduct from './MainProduct';
import Header from '../Header/Header';
import Context from '../../Context/Context';


function SearchResults() {
  const contextData = useContext(Context)
  const { selectedProduct } = contextData

  const sample = [
    {
      name: "Product 1",
      image: "https://via.placeholder.com/50"
    },
    {
      name: "Product 2",
      image: "https://via.placeholder.com/50"
    },
    {
      name: "Product 3",
      image: "https://via.placeholder.com/50"
    }
  ];

  return (
    <div className='h-screen mx-4'>
      <Header />
      <div className='flex gap-5 h-92p py-4'>
        <ProductsList products={sample} />
        <MainProduct productName={selectedProduct.title} productImage={selectedProduct.original} />
      </div>
    </div>
  )
}

export default SearchResults
