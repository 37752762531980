import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";

function Header() {
    const navigate = useNavigate()
    const handleGoBack=()=>{
        navigate(-1)
    }
    const handleLogout = () => {
        localStorage.removeItem('token'); 
        navigate("/")
    };
    return (
        <div className='flex justify-between items-center h-8p'>
            <div onClick={handleGoBack}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-12 mr-2 hover:text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                </svg>
            </div>
            <button onClick={handleLogout}
                className="flex items-center justify-center font-bold py-2 px-4 rounded-full focus:outline-none hover:text-primary"
            >
                <div className="flex items-center justify-center bg-gray-300 text-black rounded-full w-10 h-10 hover:text-primary">
                    <FontAwesomeIcon icon={faUser} />
                </div>
                <span className="text-sm hover:text-primary">Logout</span>
            </button>
        </div>
    )
}

export default Header
