import React from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { Outlet } from 'react-router-dom';

function Layout() {
    return (
        <div className='flex w-screen h-screen'>
            <Sidebar />
            <div className='flex-grow'>
                <Outlet />
            </div>
        </div>
    );
} 

export default Layout;
