import React, { useContext, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import Header from '../Header/Header';
import axios from 'axios';
import Context from '../../Context/Context';
import { useNavigate } from 'react-router-dom';
import Loader1 from '../Loader/Loader1';

function Search() {
    const [image, setImage] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [productName, setProductName] = useState('');
    const [loading, setLoading] = useState(false);
    const hostname = process.env.REACT_APP_SERVER_HOSTNAME;
    const contextData = useContext(Context);
    const { setSearchImageResults, setProductID } = contextData;
    const navigate = useNavigate();
    const token = localStorage.getItem('copyrightToken');

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        handleImage(file);
    };

    const handleFileInput = (e) => {
        const file = e.target.files[0];
        handleImage(file);
    };

    const handleImage = (file) => {
        if (!file || !(file instanceof Blob)) {
            console.error("Invalid file:", file);
            return;
        }
        setImage(file);
        const imageUrl = URL.createObjectURL(file);
        setImagePreviewUrl(imageUrl);
    };

    const handleProductNameChange = (e) => {
        setProductName(e.target.value);
    };

    const handleSearch = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (!image || !productName) {
                console.error('Image and product name are required.');
                return;
            }

            const formData = new FormData();
            formData.append('image', image);
            formData.append('productName', productName);

            const response = await axios.post(`${hostname}/api/product/addImage`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': token
                }
            });
            setSearchImageResults(response.data.inlineImages);
            setProductID(response.data.productID);
            setLoading(false);
            navigate("/searchedResult");
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleRemoveImage = () => {
        setImage(null);
        setImagePreviewUrl(null);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <>
            {loading && <Loader1 />}
            <Header />
            <div>
                <p className='text-5xl font-extrabold mt-3 text-primary m-5 text-center'>Add Product To Search</p>
                <form className='w-full flex flex-col items-center justify-center h-5/6' onSubmit={handleSearch}>
                    <div className='w-1/2'>
                        <input
                            className='my-4 border border-solid rounded border-primary w-full text-center px-4 py-3'
                            type="text"
                            placeholder='Enter Product Name Here.*'
                            value={productName}
                            onChange={handleProductNameChange}
                            required
                        />
                    </div>

                    <div
                        className="border border-none border-gray-400 rounded-lg w-1/2 h-96 flex flex-col items-center justify-center relative"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        style={{
                            backgroundImage: 'linear-gradient(rgba(240, 249, 246, 1), rgba(197, 255, 248, 1))',
                        }}
                    >
                        {imagePreviewUrl ? (
                            <>
                                <img src={imagePreviewUrl} alt="Uploaded" className="w-full h-full object-contain" />
                                <button
                                    onClick={handleRemoveImage}
                                    className="absolute top-0 right-0 m-2 p-1 bg-gray-200 rounded-full text-gray-600"
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </>
                        ) : (
                            <>
                                <FontAwesomeIcon icon={faUpload} size="3x" className="text-black" />
                                <p className="text-black mt-2 text-lg">Drag files to upload</p>
                                <button
                                    onClick={() => document.getElementById('fileInput').click()}
                                    className="mt-4 bg-gray-300 hover:bg-blue-300 py-2 px-6 rounded-full "
                                >
                                    Upload Image
                                </button>
                            </>
                        )}
                    </div>
                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        className="mt-4 hidden"
                        onChange={handleFileInput}
                    />

                    <button type='submit' className="mt-8 bg-primary text-white py-3 px-80 font-semibold rounded">Search</button>
                </form>
            </div>
        </>
    );
}

export default Search;
