import React from 'react';
import { BrowserRouter as HashRouter, Routes, Route } from 'react-router-dom';
import Auth from "./Component/Auth/Auth";
import Search from './Component/Serach/Search';
import SearchResults from './Component/SearchedResult/SearchResults';
import State from './Context/State';
import Layout from './Component/Layout/Layout';
import MyProducts from './Component/MyProducts/MyProducts';
import ActionedProducts from './Component/ActionedProducts/ActionedProducts';

export default function App() {
  return (
    <>
      <HashRouter>
        <State>
          <Routes>
            <Route exact path="/" element={<Auth />} />
            <Route element={<Layout />}>
              <Route path="/search" element={<Search />} />
              <Route path="/searchedResult" element={<SearchResults />} />
              <Route path="/myProducts" element={<MyProducts />} />
              <Route path="/product/:productID/actionedProducts" element={<ActionedProducts />} />Act
            </Route>
          </Routes>
        </State>
      </HashRouter>
    </>
  )
}
